import aboutUs from './zh-CN/aboutUs';
import footer from './zh-CN/footer';
import home from './zh-CN/home';
import partrne from './zh-CN/partrne';
export default {
  'menu.homePage': '网站首页',
  'menu.companyProfile': '公司简介',
  'menu.newsCenter': '新闻中心',
  'menu.newsCenter.companyNews': '公司新闻',
  'menu.newsCenter.tradeNews': '行业新闻',
  'menu.productInformation': '产品信息',
  'menu.qualification': '公司资质',
  'menu.partner': '合作伙伴',
  'menu.connectUs': '联系我们',
  'qualification.USB': 'USB证书',
  'qualification.quality': '质量证书',
  'qualification.environment': '环境证书',
  submit: '提交',
  ...footer,
  ...aboutUs,
  ...home,
  ...partrne,
};
