export default {
  'home.banner0.title': '致力于资源利用',
  'home.banner0.content': '青岛新之环保科技股份有限公司 证券代码：836780',
  'home.banner0.button': '浏览官网',
  'home.content0.title1': '新之环保',
  'home.content0.title2': '再生资源回收行业解决方案',
  'home.content0.title3':
    '为了增强再生资源产业的资源供给能力和创新能力，引导现有的再生资源产业调整经营方式和管理模式，深化企业间的分工与合作，加大信息、服务、基础设施、采购和销售渠道的共享力度，我们提供以下行业解决方案：',
  'home.content0Item.title1': '工厂物联网',
  'home.content0Item.title2': '交易平台',
  'home.content0Item.title3': '物流轨迹',
  'home.content0Item.title4': 'ERP与OA',
  'home.content0Item.content1':
    '提供基于传统生产线设备的物联网升级改造方案，低价高效的实现生产数字化、自动化',
  'home.content0Item.content2':
    '新再生网-再生资源交易平台提供B2B2C的再生资源原料、产品的发布、采购等功能，首家实现再生资源的大额交易',
  'home.content0Item.content3':
    '通过手机定位、车牌自动识别、起始与终点设备接收确认物流轨迹是否完整，实现货物去向的实时监管',
  'home.content0Item.content4':
    '为工厂及采购方提供专业的再生资源行业ERP、OA系统',
  'home.feature7.text1': '平台交易总额（亿元）',
  'home.feature7.text2': '支付笔数（笔）',
  'home.feature7.text3': '平台交易吨数（万吨）',
  'home.feature7.text4': '订单额（万）',
};
