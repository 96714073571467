import React from 'react';
import { FormattedMessage } from 'umi';

const policeBadge = require('@/src/../../public/img/policeBadge.png');
export const Nav00DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'nav-page' },
  logo: {
    className: 'header3-logo',
    children: require('../../public/img/logo/logo-default.png'),
    text: '新之环保 SINZE',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '/',
          children: [
            {
              children: <FormattedMessage id={'menu.homePage'} />,
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/aboutUs',
          children: [
            {
              children: <FormattedMessage id={'menu.companyProfile'} />,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item kzns88iy8yq-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: <FormattedMessage id={'menu.newsCenter'} />,
              name: 'text',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item kzns81qegu8-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <FormattedMessage id={'menu.newsCenter.companyNews'} />
                  ),
                  // href: '/aboutUs',
                },
              ],
            },
          },
          {
            className: 'item-sub',
            children: {
              className: 'item-sub-item kzns81qegu8-editor_css',
              children: [
                {
                  name: 'title',
                  className: 'item-title',
                  children: (
                    <FormattedMessage id={'menu.newsCenter.tradeNews'} />
                  ),
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/',
          children: [
            {
              children: <FormattedMessage id={'menu.productInformation'} />,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header3-item',
        children: {
          href: '/qualification',
          children: [
            {
              children: <FormattedMessage id={'menu.qualification'} />,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item5',
        className: 'header3-item',
        children: {
          href: '/partner',
          children: [
            {
              children: <FormattedMessage id={'menu.partner'} />,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item6',
        className: 'header3-item',
        children: {
          href: '/connectUs',
          children: [
            {
              children: <FormattedMessage id={'menu.connectUs'} />,
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1 kzhz93sxw7b-editor_css', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo kzhz8iqpu-editor_css',
          children: (
            <span>
              <p>ABOUT US</p>
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'footer.block0.content'} />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <span>
                  <p>WEBSITE MENU</p>
                </span>
              </span>
            </span>
          ),
          className: 'kzhz9uop0rj-editor_css',
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/aboutUs',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'menu.companyProfile'} />
                  </p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <FormattedMessage id={'menu.connectUs'} />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '/partner',
              children: (
                <span>
                  <span>
                    <p>
                      <FormattedMessage id={'menu.partner'} />
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>NEWS CENTER</p>
              </span>
            </span>
          ),
          className: 'kzhza0hz5b-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'menu.newsCenter.companyNews'} />
                  </p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'menu.newsCenter.tradeNews'} />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>PRODUCT SHOW</p>
            </span>
          ),
          className: 'kzhz87zzerk-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: 'https://www.saikul.com/',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p>新再生网</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright kzhzbick19k-editor_css',
    children: (
      <>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37028102001475"
          target="_blank"
        >
          <img src={policeBadge} alt="policeBadge" />
          鲁公网安备 37028102001475号
        </a>
        <span />
        <a href="https://beian.miit.gov.cn/#/Integrated/index">
          鲁ICP备15009892号-1
        </a>
        <div>
          Copyright © 2010-{new Date().getFullYear()} Sinze
          青岛新之环保科技股份有限公司 版权所有
        </div>
        <br />
      </>
    ),
  },
};
