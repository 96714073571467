export default {
  'partner.content5.title': 'Cooperative partner',
  'partner.content5.content':
    'Hand in hand, work together to contribute to environmental protection',
  'partner.company1': 'Anhui Supply and Marketing Commercial Corporation',
  'partner.company2': 'Anhui win-win Renewable Resources Group Co',
  'partner.company3': 'Zhejiang Xinghe Group Co',
  'partner.company4': 'Zhejiang Specialty Group Co',
  'partner.company5': 'Zhejiang Renewable Resources Group Co',
  'partner.company6': 'Qingdao Jiaobang Liren Investment development Co',
};
