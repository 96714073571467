import React from 'react';
import { Tabs, Image } from 'antd';
import styles from './index.less';
import { FormattedMessage } from 'umi';
const { TabPane } = Tabs;
const Qualification: React.FC<{}> = (props) => {
  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <span>Company qualification</span>
        <p>公司资质</p>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab={<FormattedMessage id={'qualification.USB'} />} key="1">
            <Image.PreviewGroup>
              <Image
                width={200}
                src={require('../../public/img/qualification/GRS1.jpg')}
              />
              <Image
                width={200}
                src={require('../../public/img/qualification/GRS2.jpg')}
              />
              <Image
                width={200}
                src={require('../../public/img/qualification/GRS3.jpg')}
              />
            </Image.PreviewGroup>
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={'qualification.quality'} />}
            key="2"
          >
            <Image.PreviewGroup>
              <Image
                width={200}
                src={require('../../public/img/qualification/quality1.jpg')}
              />
              <Image
                width={200}
                src={require('../../public/img/qualification/quality2.jpg')}
              />
            </Image.PreviewGroup>
          </TabPane>
          <TabPane
            tab={<FormattedMessage id={'qualification.environment'} />}
            key="3"
          >
            <Image.PreviewGroup>
              <Image
                width={200}
                src={require('../../public/img/qualification/environment1.jpg')}
              />
              <Image
                width={200}
                src={require('../../public/img/qualification/environment2.jpg')}
              />
            </Image.PreviewGroup>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Qualification;
