import React from 'react';
import { FormattedMessage } from 'umi';

export const Content51DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper kzp4dmhulhm-editor_css',
  },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <FormattedMessage id={'partner.content5.title'} />
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <span>
                <p>
                  <FormattedMessage id={'partner.content5.content'} />
                </p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kzp4dpdn4uo-editor_css',
          },
          img: {
            children: require('../../public/img/team/team-0.jpg'),
            className: 'kzp4d391av-editor_css',
          },
          content: {
            children: (
              <span>
                <p>安徽省供销商业总公司</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: require('../../public/img/team/team-1.jpg'),
            className: 'kzp4dejlfo-editor_css',
          },
          content: {
            children: (
              <span>
                <p>安徽双赢再生资源集团有限公司</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: require('../../public/img/team/team-2.jpg'),
            className: 'kzp4dgcxmw-editor_css',
          },
          content: {
            children: (
              <span>
                <p>浙江省兴合集团有限责任公司</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: require('../../public/img/team/team-3.jpg'),
            className: 'kzp4dj9mlfq-editor_css',
          },
          content: {
            children: (
              <span>
                <p>
                  浙江特产集团有限公司
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: require('../../public/img/team/team-4.jpg'),
            className: 'kzp4cd4bi4-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>浙江省再生资源集团有限公司</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: require('../../public/img/team/team-5.jpg'),
            className: 'kzp4dcnk7uk-editor_css',
          },
          content: {
            children: (
              <span>
                <p>青岛胶邦里仁投资发展有限公司</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
