import React from 'react';
import { FormattedMessage } from 'umi';

export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        <p>
          <FormattedMessage id={'home.banner0.title'} />
        </p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>
          <FormattedMessage id={'home.banner0.content'} />
        </p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <a href="https://www.saikul.com/">
          <FormattedMessage id={'home.banner0.button'} />
        </a>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title~kzhxsfsbn8',
        className: 'kzhuezbi71-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id={'home.content0.title1'} />
            </p>
          </span>
        ),
      },
      {
        name: 'title',
        children: (
          <span>
            <p>
              <FormattedMessage id={'home.content0.title2'} />
            </p>
          </span>
        ),
      },
      {
        name: 'title~kzhxqbvnpq',
        className: 'kzhxqdmijd-editor_css',
        children: (
          <span>
            <p>
              <FormattedMessage id={'home.content0.title3'} />
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhxte3jon-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <FormattedMessage id={'home.content0Item.title1'} />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <FormattedMessage id={'home.content0Item.content1'} />
                      </p>
                    </span>
                  </span>
                </span>
              ),
              className: 'kzhxtko2y-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhxtsm57lp-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      <FormattedMessage id={'home.content0Item.title2'} />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.content0Item.content2'} />
                  </p>
                </span>
              ),
              className: 'kzhxu0diwv-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhxn2en9i-editor_css',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.content0Item.title3'} />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.content0Item.content3'} />
                  </p>
                </span>
              ),
              className: 'kzhxpe1vbqe-editor_css',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhxudlrj1n-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <FormattedMessage id={'home.content0Item.title4'} />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.content0Item.content4'} />
                  </p>
                </span>
              ),
              className: 'kzhxuig4j3k-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  OverPack: { className: 'home-page feature7', playScale: 0.3 },
  Carousel: {
    className: 'feature7-content',
    dots: false,
    wrapper: { className: 'feature7-content-wrapper' },
    titleWrapper: {
      className: 'feature7-title-wrapper',
      title: { className: 'feature7-title' },
    },
    children: [
      {
        title: {
          className: 'feature7-title-text',
          children: (
            <span>
              <span>Our Business Achievements</span>
              <p>公司业务范围和合作伙伴</p>
            </span>
          ),
        },
        className: 'feature7-item',
        name: 'block0',
        children: [
          {
            md: 12,
            xs: 24,
            className: 'feature7-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature7-number',
              unit: {
                className: 'feature7-unit',
                children: (
                  <span>
                    <p>
                      <br />
                    </p>
                  </span>
                ),
              },
              toText: true,
              children: '400.47',
            },
            children: {
              className: 'feature7-text',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.feature7.text1'} />
                  </p>
                </span>
              ),
            },
          },
          {
            md: 12,
            xs: 24,
            className: 'feature7-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature7-number',
              unit: {
                className: 'feature7-unit',
                children: (
                  <span>
                    <span>
                      <p>
                        <br />
                      </p>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '339,772',
            },
            children: {
              className: 'feature7-text',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <FormattedMessage id={'home.feature7.text2'} />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 12,
            xs: 24,
            className: 'feature7-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature7-number',
              unit: {
                className: 'feature7-unit',
                children: (
                  <span>
                    <p>
                      <br />
                    </p>
                  </span>
                ),
              },
              toText: true,
              children: '1,327.05',
            },
            children: {
              className: 'feature7-text',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.feature7.text3'} />
                  </p>
                </span>
              ),
            },
          },
          {
            md: 12,
            xs: 24,
            className: 'feature7-number-wrapper',
            name: 'child~kznubivy4zq',
            number: {
              className: 'feature7-number',
              unit: {
                className: 'feature7-unit',
                children: (
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                ),
              },
              toText: true,
              children: '3,837,752.83',
            },
            children: {
              className: 'feature7-text',
              children: (
                <span>
                  <p>
                    <FormattedMessage id={'home.feature7.text4'} />
                  </p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};

export const Feature01DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper kzhz0w45j9-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'title',
              className: 'content0-block-title kzhynntkfrn-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Contact Us</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>联系我们</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'kzhyo1cnkh-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kzhyzkkwheh-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhyo7pl1xm-editor_css',
              children: (
                <span>
                  <p>Address</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: '青岛市胶州市里岔镇里岔工业园区新之路',
              className: 'kzhz055zbit-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kzhyzkkwheh-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhyo7pl1xm-editor_css',
              children: (
                <span>
                  <span>
                    <p>Phone</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>400-900-8285</p>
                </span>
              ),
              className: 'kzhz055zbit-editor_css',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kzhyzkkwheh-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title kzhyo7pl1xm-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Email</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>info@sinzetech.com</p>
                </span>
              ),
              className: 'kzhz055zbit-editor_css',
            },
          ],
        },
      },
    ],
  },
};
