import React from 'react';
import { FormattedMessage } from 'umi';
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'wrapper-title',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <FormattedMessage id={'aboutUs.content.title'} />
            </p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              <FormattedMessage id={'aboutUs.content.content'} />
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kzp5gwcsw29-editor_css',
  },
  OverPack: {
    className: 'home-page content1',
    playScale: 0.3,
    appear: true,
    always: true,
    replay: false,
  },
  imgWrapper: {
    className: 'content1-img kzp5h1c5mu-editor_css',
    md: 8,
    xs: 24,
  },
  img: { children: require('../../public/img/team/logo-360.jpg') },
  textWrapper: { className: 'content1-text', md: 16, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <p>
            <FormattedMessage id={'aboutUs.feature.title'} />
          </p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <FormattedMessage id={'aboutUs.feature.content'} />
      </span>
    ),
  },
};
export const Teams41DataSource = {
  wrapper: { className: 'home-page-wrapper content8-wrapper' },
  page: { className: 'home-page content8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children: 'our team',
        className: 'title-image',
      },
      { name: 'title', children: '我们的团队', className: 'title-h1' },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block0',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/qdsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '青岛新之环保科技股份有限公司',
          },
          content: {
            className: 'content8-content',
            children: '股票代码：836780',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block1',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/gjsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '山东网上有名网络科技有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block2',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/ahsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '安徽新之环保科技有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block3',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/fjsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '福建新之环保科技有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block4',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/zjsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '浙江新之环保科技有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block5',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/hzsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '杭州新之电子商务有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block6',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/qdsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '青岛新计划再生资源有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block7',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/qlsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '宣城市新计划再生资源有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
      {
        name: 'block8',
        md: 6,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: require('../../public/img/team/nbsinze.jpg'),
          },
          title: {
            className: 'content8-title',
            children: '宁波曼加贸易有限公司',
          },
          content: {
            className: 'content8-content',
            phone: '400-900-8285',
          },
        },
      },
    ],
  },
};
