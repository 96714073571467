export default {
  'partner.content5.title': '合作伙伴',
  'partner.content5.content': '携手并进、齐心协力，为环保事业做贡献',
  'partner.company1': '安徽省供销商业总公司',
  'partner.company2': '安徽双赢再生资源集团有限公司',
  'partner.company3': '浙江省兴合集团有限责任公司',
  'partner.company4': '浙江特产集团有限公司',
  'partner.company5': '浙江省再生资源集团有限公司',
  'partner.company6': '青岛胶邦里仁投资发展有限公司',
};
