import aboutUs from './en-US/aboutUs';
import footer from './en-US/footer';
import home from './en-US/home';
import partrne from './en-US//partrne';
export default {
  'menu.homePage': 'Home Page',
  'menu.companyProfile': 'Company Profile',
  'menu.newsCenter': 'News Center',
  'menu.newsCenter.companyNews': 'Company News',
  'menu.newsCenter.tradeNews': 'Trade News',
  'menu.productInformation': 'Product Information',
  'menu.qualification': 'Qualification',
  'menu.partner': 'Partner',
  'menu.connectUs': 'Connect Us',
  'qualification.USB': 'USB certificate',
  'qualification.quality': 'Certificate of quality',
  'qualification.environment': 'Certificate of environment',
  submit: 'submit',
  ...footer,
  ...home,
  ...aboutUs,
  ...partrne,
};
