/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import { fetchHomeData } from '@/services/home';
import { FormattedMessage } from 'umi';

import Banner0 from './Banner0';
import Content0 from './Content0';
import Feature6 from './Feature6';
import Feature0 from './Feature0';

import {
  Banner00DataSource,
  Content00DataSource,
  Feature70DataSource,
  Feature01DataSource,
} from './data.source';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      landingData: '',
      Feature70DataSource: '',
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
    this.init();
  }

  init = () => {
    fetchHomeData().then((res) => {
      this.setState({
        landingData: res.saikulIndexDataView?.[0],
      });
      if (res.saikulIndexDataView?.[0]) {
        const {
          lastMonthTradingVolume,
          totalTaxAmount,
          totalTaxableOrderAmount,
          totalTaxableOrderCount,
          totalTradingAmount,
          totalTradingCount,
          totalTradingVolume,
        } = res.saikulIndexDataView?.[0];
        const amountMoney = (totalTradingAmount / Math.pow(10, 8)).toFixed(2);
        const amountTonnage = (totalTradingVolume / Math.pow(10, 4)).toFixed(2);
        const amountOrder = (totalTaxableOrderAmount / Math.pow(10, 4)).toFixed(
          2,
        );

        this.setState({
          Feature70DataSource: {
            wrapper: { className: 'home-page-wrapper feature7-wrapper' },
            OverPack: { className: 'home-page feature7', playScale: 0.3 },
            Carousel: {
              className: 'feature7-content',
              dots: false,
              wrapper: { className: 'feature7-content-wrapper' },
              titleWrapper: {
                className: 'feature7-title-wrapper',
                title: { className: 'feature7-title' },
              },
              children: [
                {
                  title: {
                    className: 'feature7-title-text',
                    children: (
                      <span>
                        <span>Our Business Achievements</span>
                        <p>公司业务范围和合作伙伴</p>
                      </span>
                    ),
                  },
                  className: 'feature7-item',
                  name: 'block0',
                  children: [
                    {
                      md: 12,
                      xs: 24,
                      className: 'feature7-number-wrapper',
                      name: 'child0',
                      number: {
                        className: 'feature7-number',
                        unit: {
                          className: 'feature7-unit',
                          children: (
                            <span>
                              <p>
                                <br />
                              </p>
                            </span>
                          ),
                        },
                        toText: true,
                        children: `${amountMoney}`,
                      },
                      children: {
                        className: 'feature7-text',
                        children: (
                          <span>
                            <p>
                              <FormattedMessage id={'home.feature7.text1'} />
                            </p>
                          </span>
                        ),
                      },
                    },
                    {
                      md: 12,
                      xs: 24,
                      className: 'feature7-number-wrapper',
                      name: 'child1',
                      number: {
                        className: 'feature7-number',
                        unit: {
                          className: 'feature7-unit',
                          children: (
                            <span>
                              <span>
                                <p>
                                  <br />
                                </p>
                              </span>
                            </span>
                          ),
                        },
                        toText: true,
                        children: `${totalTradingCount}`,
                      },
                      children: {
                        className: 'feature7-text',
                        children: (
                          <span>
                            <span>
                              <span>
                                <p>
                                  <FormattedMessage
                                    id={'home.feature7.text2'}
                                  />
                                </p>
                              </span>
                            </span>
                          </span>
                        ),
                      },
                    },
                    {
                      md: 12,
                      xs: 24,
                      className: 'feature7-number-wrapper',
                      name: 'child2',
                      number: {
                        className: 'feature7-number',
                        unit: {
                          className: 'feature7-unit',
                          children: (
                            <span>
                              <p>
                                <br />
                              </p>
                            </span>
                          ),
                        },
                        toText: true,
                        children: `${amountTonnage}`,
                      },
                      children: {
                        className: 'feature7-text',
                        children: (
                          <span>
                            <p>
                              <FormattedMessage id={'home.feature7.text3'} />
                            </p>
                          </span>
                        ),
                      },
                    },
                    {
                      md: 12,
                      xs: 24,
                      className: 'feature7-number-wrapper',
                      name: 'child~kznubivy4zq',
                      number: {
                        className: 'feature7-number',
                        unit: {
                          className: 'feature7-unit',
                          children: (
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      <br />
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          ),
                        },
                        toText: true,
                        children: `${amountOrder}`,
                      },
                      children: {
                        className: 'feature7-text',
                        children: (
                          <span>
                            <p>
                              <FormattedMessage id={'home.feature7.text4'} />
                            </p>
                          </span>
                        ),
                      },
                    },
                  ],
                },
              ],
            },
          },
        });
      }
    });
  };
  render() {
    const children = [
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="Content0_0"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature6
        id="Feature7_0"
        key="Feature7_0"
        dataSource={this.state.Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_1"
        key="Feature0_1"
        dataSource={Feature01DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
