import React from 'react';
import { Form, Input, InputNumber, Button, Col, Row } from 'antd';
import styles from './connectUs.less';
import { FormattedMessage } from 'umi';

const ConnectUs: React.FC<{}> = (props) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const validateMessages = {
    required: '${label}不能为空',
  };

  const Demo = () => {
    const onFinish = (values: any) => {
      console.log(values);
    };
  };
  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <span>Online message</span>
        <p>在线留言</p>
        <Form
          className={styles.formBox}
          name="nest-messages"
          layout="inline"
          labelAlign="right"
          //   onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name={['user', 'name']}
                label="姓名"
                rules={[{ required: true }]}
              >
                <Input placeholder="name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['user', 'email']}
                label="Email"
                rules={[
                  { required: true },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: '邮箱格式不正确',
                  },
                ]}
              >
                <Input placeholder="email" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                name={['user', 'phone']}
                label="电话"
                rules={[
                  { required: true },
                  {
                    pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
                    message: '电话号码格式不正确',
                  },
                ]}
              >
                <Input placeholder="phone number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={['user', 'QQ']} label=" Q Q">
                <Input placeholder="QQ" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name={['user', 'introduction']} label="备注">
            <Input.TextArea placeholder="remark" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id={'submit'} />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ConnectUs;
