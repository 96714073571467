export default {
  'home.banner0.title': 'Commitment to resource utilization',
  'home.banner0.content':
    'Qingdao Sinze Environmental Technology. Stock code: 836780 ',
  'home.banner0.button': 'Browse the website',
  'home.content0.title1': 'Sinze Environmental Protection',
  'home.content0.title2': 'Renewable resource recycling industry solutions',
  'home.content0.title3':
    'In order to enhance the resource supply capacity and innovation capacity of the renewable resource industry, guide the existing renewable resource industry to adjust its operation mode and management mode, deepen the division of labor and cooperation among enterprises, and increase the sharing of information, services, infrastructure, procurement and sales channels, we provide the following industry solutions：',
  'home.content0Item.title1': 'FIOT',
  'home.content0Item.title2': '交易平台',
  'home.content0Item.title3': 'trading platform',
  'home.content0Item.title4': 'ERP与OA',
  'home.content0Item.content1':
    'Provide Internet of Things upgrade and transformation scheme based on traditional production line equipment to realize digitalization and automation of production at a low price and high efficiency',
  'home.content0Item.content2':
    'New Renewable resources trading platform provides B2B2C renewable resources raw materials, product release, procurement and other functions, the first to realize a large transaction of renewable resources',
  'home.content0Item.content3':
    'Through mobile phone positioning license plate automatic recognition start and end equipment to receive and confirm whether the logistics track is complete, to achieve real-time supervision of the whereabouts of goods',
  'home.content0Item.content4':
    'Provide professional ERP and OA system for factories and buyers of renewable resources industry',
  'home.feature7.text1': 'Total amount（a hundred million）',
  'home.feature7.text2': 'Pay number',
  'home.feature7.text3': 'Tunnage（Million tons）',
  'home.feature7.text4': 'Order amount（ten thousand）',
};
