import React, { useState, useEffect } from 'react';
import { OrganizationTreeGraph } from '@ant-design/charts';

const DemoOrganizationGraph1: React.FC = () => {
  const data = {
    id: 'root',
    label: '青岛新之环保\n科技股份有限公司',
    style: {
      fill: 'red',
    },
    size: [200, 50],
    // 建议使用 bae64 数据
    img: 'https://avatars.githubusercontent.com/u/31396322?v=4',
    children: [
      {
        id: 'c1',
        label: '青岛新计划\n再生资源科技有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c2',
        label: '宁波曼加\n贸易有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c3',
        label: '青岛新之\n电子商务有限公司',
        style: {
          fill: 'red',
        },
        children: [
          {
            id: 'c1-2-1',
            label: '北京新之科技有限公司',
          },
          {
            id: 'c1-2-2',
            label: '浙江广聚新之\n信息科技有限公司',
          },
          {
            id: 'c1-2-3',
            label: '青岛广聚新之\n信息科技有限公司',
          },
          {
            id: 'c1-2-4',
            label: '安徽广聚新之\n信息科技有限公司',
          },
          {
            id: 'c1-2-5',
            label: '宁国必要\n信息科技有限公司',
          },
          {
            id: 'c1-2-6',
            label: '绩溪新之\n信息科技有限公司',
          },
          {
            id: 'c1-2-7',
            label: '江苏冠中元\n信息科技有限公司',
          },
          {
            id: 'c1-2-8',
            label: '杭州码农和他的朋友们\n网络技术服务有限公司',
          },
          {
            id: 'c1-2-9',
            label: '甘肃广聚新之\n网络技术服务有限公司',
          },
          {
            id: 'c1-2-10',
            label: '安徽新矿\n网络科技有限公司',
          },
          {
            id: 'c1-2-11',
            label: '安徽泰德隆新之\n电子商务有限公司',
          },
          {
            id: 'c1-2-12',
            label: '界首广聚新之\n数字科技有限公司',
          },
          {
            id: 'c1-2-13',
            label: '泾县广聚新之\n信息科技有限公司',
          },
          {
            id: 'c1-2-14',
            label: '青岛广源\n电子商务有限公司',
          },
        ],
      },
      {
        id: 'c4',
        label: '福建新之\n环保科技有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c5',
        label: '安徽新之\n环保科技有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c6',
        label: '宣城市新计划\n再生资源有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c7',
        label: '浙江新之\n环保科技有限公司',
        style: {
          fill: '#0265CB',
        },
      },
      {
        id: 'c8',
        label: '山东网上有名\n网络科技有限公司',
        style: {
          fill: 'red',
        },
      },
    ],
  };

  /**
   * 遍历树的方法，仅作为演示 demo 中使用，实际使用中根据具体需求而定
   */
  const traverseTree = <T extends { children?: T[] }>(
    data: T,
    fn: (param: T) => boolean,
  ) => {
    if (typeof fn !== 'function') {
      return;
    }

    if (fn(data) === false) {
      return false;
    }

    if (data && data.children) {
      for (let i = data.children.length - 1; i >= 0; i--) {
        if (!traverseTree(data.children[i], fn)) return false;
      }
    }
    return true;
  };

  traverseTree(data as any, (d: any) => {
    d.leftIcon = {
      style: {
        fill: 'red',
        stroke: 'red',
      },
      img: 'https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*Q_FQT6nwEC8AAAAAAAAAAABkARQnAQ',
    };
    return true;
  });
  const nodeLabelCfg = {
    style: {
      fill: '#ffffff',
    },
  };
  const nodeStyle = {
    fill: 'green',
  };
  return (
    <div
      className="home-page-wrapper"
      style={{ margin: 'auto', marginTop: '30' }}
    >
      <OrganizationTreeGraph
        data={data}
        nodeStyle={nodeStyle}
        nodeLabelCfg={nodeLabelCfg}
        nodeType="rect"
        enableEdit={false}
        minimapCfg={{ show: true }}
      />
      ;
    </div>
  );
};

export default DemoOrganizationGraph1;
